<template>
    <LayoutNext>
        <template #page-title>
            Dashboard
        </template>
        <MDBAlert v-if="isUser && userDetail.isNewUser" color="warning" static class="mb-3 mb-md-4 px-4 py-3">
            <i class="fas fa-info-circle me-2"></i>
            <span>
                Welcome, <strong style="text-transform: capitalize;">{{userDetail.firstName}}</strong>! Your first earnings report will be live in about 30-days. <a href="//CreatorShield.com/support" target="_blank" rel="noopener noreferrer">See the earnings
                    schedule</a>.
            </span>
        </MDBAlert>
        <div>
            <div :class="isMobile ? 'd-block' : 'd-flex gap-4 mb-4'">
                <div :class="isMobile ? 'd-block mb-3' : 'first-row gap-4'">
                    <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''" style="min-width: max-content;">
                        <MDBCardHeader> Previous Month Revenue </MDBCardHeader>
                        <MDBCardBody>
                            <MDBCardTitle>
                                <div class="image-container d-flex justify-content-center align-items-center"
                                    v-if="widgetLoader.loader1">
                                    <div class="loader"></div>
                                    <img src="@/assets/cs-loader.png" alt="Loading image">
                                </div>
                                <template v-else>
                                    ${{ addCommaNumbers(assetWidgets.revenueMonth.toFixed(2)) }}
                                </template>
                            </MDBCardTitle>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''" style="min-width: max-content;">
                        <MDBCardHeader>Current Year Revenue</MDBCardHeader>
                        <MDBCardBody>
                            <MDBCardTitle>
                                <div class="image-container d-flex justify-content-center align-items-center"
                                    v-if="widgetLoader.loader2">
                                    <div class="loader"></div>
                                    <img src="../../assets/cs-loader.png" alt="Loading image">
                                </div>
                                <template v-else>
                                    ${{ addCommaNumbers(assetWidgets.revenueYear.toFixed(2)) }}
                                </template>
                            </MDBCardTitle>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''" style="min-width: max-content;">
                        <MDBCardHeader>Total Revenue</MDBCardHeader>
                        <MDBCardBody>
                            <MDBCardTitle>
                                <div class="image-container d-flex justify-content-center align-items-center"
                                    v-if="widgetLoader.loader3">
                                    <div class="loader"></div>
                                    <img src="../../assets/cs-loader.png" alt="Loading image">
                                </div>
                                <template v-else>
                                    ${{ addCommaNumbers(assetWidgets.revenueTotal.toFixed(2)) }}
                                </template>
                            </MDBCardTitle>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard style="min-width: max-content;">
                        <MDBCardHeader>Assigned Labels</MDBCardHeader>
                        <MDBCardBody>
                            <MDBCardTitle>
                                <div class="image-container d-flex justify-content-center align-items-center"
                                    v-if="widgetLoader.loader4">
                                    <div class="loader"></div>
                                    <img src="../../assets/cs-loader.png" alt="Loading image">
                                </div>
                                <template v-else>
                                    {{ addCommaNumbers(assetWidgets.AssignedLabelsCount) }}
                                </template>
                            </MDBCardTitle>
                        </MDBCardBody>
                    </MDBCard>
                </div>
                <MDBCard class="card mb-3 mb-md-0" style="width: 100%;">
                    <MDBCardHeader> Revenue Chart </MDBCardHeader>
                    <MDBCardBody>
                        <MDBChart type="line" indexAxis="y" :data="revenueChartData" id="revenue-chart" />
                    </MDBCardBody>
                </MDBCard>
            </div>
            <div class="d-block gap-3 mb-3">
                <div :class="isMobile ? 'd-block' : 'second-row gap-4 mb-4'" v-if="secondRow">
                    <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''">
                        <MDBCardHeader>Gross Month Revenue</MDBCardHeader>
                        <MDBCardBody>
                            <MDBCardTitle>
                                <div class="image-container d-flex justify-content-center align-items-center"
                                    v-if="widgetLoader.loader5">
                                    <div class="loader"></div>
                                    <img src="../../assets/cs-loader.png" alt="Loading image">
                                </div>
                                <template v-else>
                                    ${{ addCommaNumbers(assetWidgets.grossMonth.toFixed(2)) }}
                                </template>
                            </MDBCardTitle>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''">
                        <MDBCardHeader>Gross Year Revenue</MDBCardHeader>
                        <MDBCardBody>
                            <MDBCardTitle>
                                <div class="image-container d-flex justify-content-center align-items-center"
                                    v-if="widgetLoader.loader6">
                                    <div class="loader"></div>
                                    <img src="../../assets/cs-loader.png" alt="Loading image">
                                </div>
                                <template v-else>
                                    ${{ addCommaNumbers(assetWidgets.grossYear.toFixed(2)) }}
                                </template>
                            </MDBCardTitle>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard>
                        <MDBCardHeader>Gross Total Revenue</MDBCardHeader>
                        <MDBCardBody>
                            <MDBCardTitle>
                                <div class="image-container d-flex justify-content-center align-items-center"
                                    v-if="widgetLoader.loader7">
                                    <div class="loader"></div>
                                    <img src="../../assets/cs-loader.png" alt="Loading image">
                                </div>
                                <template v-else>
                                    ${{ addCommaNumbers(assetWidgets.grossTotal.toFixed(2)) }}
                                </template>
                            </MDBCardTitle>
                        </MDBCardBody>
                    </MDBCard>
                </div>
            </div>
            <MDBCard>
                <MDBCardHeader> Revenue Data </MDBCardHeader>
                <MDBCardBody class="overflow-auto">
                    <RevenuesTableComponent />
                </MDBCardBody>
            </MDBCard>
        </div>
    </LayoutNext>
</template>

<script setup>
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBChart, MDBAlert } from "mdb-vue-ui-kit";
import { onMounted, ref, onUnmounted, computed, onBeforeUnmount } from "vue";
import { useUserDetailStore } from "../../store/userDetailStore";
import { useTitle } from "@vueuse/core";
import { storeToRefs } from "pinia";
import axios from "axios";
import { GetRevenueWidgets } from "../../services/Revenue/GetRevenueWidget";
import { addCommaNumbers } from "../../helpers/addCommaNumbers";
import RevenuesTableComponent from "../../components/RevenuesTableComponent.vue";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { useImpersonateStore } from "../../store/impersonateStore";

useTitle("Dashboard | CreatorShield");

const userDetailStore = useUserDetailStore();
const { userDetail, isUser } = storeToRefs(userDetailStore);
const revenueChartData = ref({});

const assetWidgets = ref({
    revenueMonth: 0,
    revenueYear: 0,
    revenueTotal: 0,
    AssignedLabelsCount: 0,
    grossMonth: 0,
    grossYear: 0,
    grossTotal: 0,
});

const widgetLoader = ref({
    loader1: true,
    loader2: true,
    loader3: true,
    loader4: true,
    loader5: true,
    loader6: true,
    loader7: true,
});

const impersonateStore = useImpersonateStore();
const { isImpersonating, userImpersonateDetail } =
    storeToRefs(impersonateStore);
onUnmounted(() => {
    isImpersonating.value = false;
});

onMounted(async () => {
    if (userDetail.value) {
        const impersonateId = isImpersonating.value
            ? userImpersonateDetail.value.id
            : null;
        const chartUrl = impersonateId
            ? `api/revenues/ChartData?userId=${impersonateId}&isImpersonation=true`
            : "api/revenues/ChartData";
        await axios
            .get(chartUrl)
            .then(function (response) {
                const dataset = response.data.data;
                const labels = response.data.labels;
                revenueChartData.value = {
                    labels,
                    datasets: [
                        {
                            data: dataset,
                            label: "Revenue ($)",
                            backgroundColor: new Array(dataset.length).fill(
                                "rgba(2, 6, 27, 0.7)"
                            ),
                            borderColor: new Array(dataset.length).fill("#fc5818"),
                            borderWidth: 0.5,
                        },
                    ],
                };
            })
            .catch(function (error) {
                alert("An error occurred during request " + error);
            });
        GetRevenueWidgets("RevenueMonth", impersonateId).then((response) => {
            assetWidgets.value.revenueMonth = response;
            widgetLoader.value.loader1 = false;
        });
        GetRevenueWidgets("RevenueYear", impersonateId).then((response) => {
            assetWidgets.value.revenueYear = response;
            widgetLoader.value.loader2 = false;
        });
        GetRevenueWidgets("RevenueTotal", impersonateId).then((response) => {
            assetWidgets.value.revenueTotal = response;
            widgetLoader.value.loader3 = false;
        });
        GetRevenueWidgets("AssignedLabelsCount", impersonateId).then((response) => {
            assetWidgets.value.AssignedLabelsCount = response;
            widgetLoader.value.loader4 = false;
        });
        GetRevenueWidgets("GrossRevenueMonth", impersonateId).then((response) => {
            assetWidgets.value.grossMonth = response;
            widgetLoader.value.loader5 = false;
        });
        GetRevenueWidgets("GrossRevenueYear", impersonateId).then((response) => {
            assetWidgets.value.grossYear = response;
            widgetLoader.value.loader6 = false;
        });
        GetRevenueWidgets("GrossRevenueTotal", impersonateId).then((response) => {
            assetWidgets.value.grossTotal = response;
            widgetLoader.value.loader7 = false;
        });
    } else {
        console.error("userDetail is missing or not loaded yet.");
    }
});

const secondRow = computed(() => {
    if (isImpersonating.value) {
        return userImpersonateDetail.value.userTypeId == 1;
    }
    return userDetail.value.userTypeId == 1;
});

const isMobile = ref(window.innerWidth <= 767);
const updateResolution = () => {
    isMobile.value = window.innerWidth <= 767;
};

onMounted(() => {
    window.addEventListener('resize', updateResolution);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateResolution);
});
</script>

<style scoped>
.first-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.second-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.spinner-grow {
    color: var(--accent);
}

.card {
    border-radius: 16px;
    box-shadow: unset;
    border: 1px solid rgba(0, 0, 0, .125);
}

.card-header {
    color: var(--accent);
    font-family: 'GilroyBold', sans-serif;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    text-align: center;
}

.card-title {
    color: var(--primary);
    width: 100%;
    letter-spacing: 1.5px;
}

.card-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}


.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>